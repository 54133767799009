














































import { PropType } from 'vue';
import { TStreamer } from '@/api/schema';

import CodeBlock from '@/components/code-blocks/CodeBlock.vue';

import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { EStreamingType } from '@/api/schema';

export default {
  name: 'CopyStakeStreamerBroadcastSettingsDialog',
  components: {
    CodeBlock
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    streamer: {
      type: Object as PropType<TStreamer>,
      default: null
    }
  },
  data(): any {
    return {
      streamingType: EStreamingType.WHIP,
      isCopied: false
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    copyToClipBoard,
    handlerOnCopySettings(): void {
      this.isCopied = true;
      copyToClipBoard(
        `Service: ${this.streamingType}\nStream URL: ${this.streamer.streamUrl}\nStream Key: ${this.streamer.streamKey}`,
        null,
        true
      );
      setTimeout(() => (this.isCopied = false), 1000);
    },
    close(): void {
      this.show = false;
      this.$emit('close');
    }
  }
};
